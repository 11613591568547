@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,300;9..40,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700;900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: DM Sans; */
}

@media (max-width: 480px) {
  body {
    padding-top: 80px;
  }
}

.getAQuote.MuiButtonBase-root.MuiButton-root.MuiButton-contained {
  color: #0e0d22;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 10px 20px;
  border-radius: 7px;
  background: #fff;
  margin-right: 80px;
  position: relative;
  z-index: 1;
  transition: background 1s ease-in-out;
  @media (max-width: 480px) {
    display: none;
  }
}

.getAQuote.MuiButtonBase-root.MuiButton-root.MuiButton-contained::before {
  position: absolute;
  content: '';
  left: 0;
  width: 0;
  height: 100%;
  background: linear-gradient(96deg, #9501f4 5.04%, #f00299 90.65%);
  z-index: -1;
  transition: all 0.5s;
}

.getAQuote.MuiButtonBase-root.MuiButton-root.MuiButton-contained:hover {
  color: #fff;
}

.getAQuote.MuiButtonBase-root.MuiButton-root.MuiButton-contained:hover::before {
  width: 100%;
  color: #fff;
  border-radius: 7px;
}

@media (max-width: 480px) {
  .getAQuote.MuiButtonBase-root.MuiButton-root.MuiButton-contained {
    display: none;
  }
}

.getStarted.MuiButtonBase-root.MuiButton-root.MuiButton-contained {
  color: #0e0d22;
  font-family: DM Sans;
  font-size: 16.819px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-radius: 7.606px;
  background: #fff;
  padding: 12px 32px;
  margin-top: 40px;
  position: relative;
  z-index: 1;
  transition: background 1s ease-in-out;
}

@media (max-width: 480px) {
  .getStarted.MuiButtonBase-root.MuiButton-root.MuiButton-contained {
    margin-top: 100px; /* 100px */
    padding: 12px 15px;
  }
}

.getStarted.MuiButtonBase-root.MuiButton-root.MuiButton-contained::before {
  position: absolute;
  content: '';
  left: 0;
  width: 0;
  height: 100%;
  background: linear-gradient(96deg, #9501f4 5.04%, #f00299 90.65%);
  z-index: -1;
  transition: all 0.5s;
}

.getStarted.MuiButtonBase-root.MuiButton-root.MuiButton-contained:hover {
  color: #fff;
}

.getStarted.MuiButtonBase-root.MuiButton-root.MuiButton-contained:hover::before {
  width: 100%;
  border-radius: 7px;
}

.getStarted.button.MuiButtonBase-root.MuiButton-root.MuiButton-contained {
  color: #0e0d22;
  font-family: DM Sans;
  font-size: 16.819px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-radius: 7.606px;
  background: #fff;
  padding: 12px 32px;
  margin-top: 40px;
  align-self: baseline;
  padding: 13px 26px;
  @media (max-width: 480px) {
    margin: 40px auto 0 auto;
    padding: 12px 15px;
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .getStarted.button.MuiButtonBase-root.MuiButton-root.MuiButton-contained {
    margin: 40px auto 0 auto;
    padding: 12px 15px;
    font-size: 14px;
  }
}

.iconButton.MuiButtonBase-root.MuiButton-root.MuiButton-contained {
  background: #0d0c22;
  border-radius: 50%;
  @media (max-width: 480px) {
    display: none;
  }
}

@media (max-width: 480px) {
  .iconButton.MuiButtonBase-root.MuiButton-root.MuiButton-contained {
    display: none;
  }
}

.menuIcon.MuiButtonBase-root.MuiButton-root.MuiButton-contained {
  display: none;
  @media (max-width: 480px) {
    display: block;
    background-color: #0d0c22;
    padding-right: 10px;
  }
}

@media (max-width: 480px) {
  .menuIcon.MuiButtonBase-root.MuiButton-root.MuiButton-contained {
    display: block;
    background-color: #0d0c22;
    padding-right: 10px;
  }
}

@media (max-width: 480px) {
  .relative {
    display: block;
  }
}
